<template>
  <div class="relocate">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url('img/csr/header-csr.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h1>
          Relocate to sweden? <br />
          välkommen.
        </h1>
        <p>
          We'll help you every step of the way towards a new world of personal and professional
          benefits.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="content">
        <h3>Living and working in Sweden</h3>
        <div class="row">
          <div class="col-md-4">
            <h4>Hierarchy</h4>
            <img src="img/relocate/jerarchy.jpg" alt="hierarchu" />
            <p>
              The Scandinavian workplace culture is based on a mix of teamwork and individual
              responsibility, where each role in the group is viewed as equally important, and
              consensus is generally central to decision-making. This kind of structure relies on
              each team member showing mutual respect and responsibility for their individual tasks.
            </p>
          </div>

          <div class="col-md-4">
            <h4>Personal life</h4>
            <img src="img/relocate/personal-life.jpg" alt="personal life" />
            <p>
              Scandinavian companies are commonly known for prioritizing an employees’ private life,
              driven by the philosophy that happy, healthy employees are more likely to be
              productive and efficient. By being valued as individuals, staff can do a better job
              and feel more motivated in their everyday working lives and beyond.
            </p>
          </div>

          <div class="col-md-4">
            <h4>Inclusive and forward-thinking</h4>
            <img src="img/relocate/forward.jpg" alt="Inclusive and forward-thinking" />
            <p>
              Nordic countries are also known for acknowledging and valuing the importance of gender
              equality and enforcing trust and honesty in the workplace. As one of the world’s most
              innovative nations, Sweden has invented the modern seatbelt, safety matches, the
              pacemaker, recent innovations include Spotify, Skype, and more.
            </p>
          </div>

          <div class="col-md-4">
            <h4>Living and working in Sweden</h4>
            <img src="img/relocate/living-in-sweden.jpg" alt="living in sweden" />
            <p>
              Living and working in Sweden Sweden is the third-largest country in the EU, with major
              metropolitan centers such as the capital, Stockholm, as well as Malmö and Gothenburg.
              Proudly multicultural, rich in diversity, and with a flourishing business environment,
              Sweden is often associated with progressive politics and generous social benefits: and
              there’s also IKEA, Zlatan Ibrahimović and ABBA, of course.
            </p>
          </div>

          <div class="col-md-4">
            <h4>Family benefits</h4>
            <img src="img/relocate/family.jpg" alt="Family benefits" />
            <p>
              Sweden is known for prioritizing personal aspects of life in its labor laws. Other
              benefits include the receipt of compensation for lost income when missing work to take
              care of a sick child. Parents are also entitled to 480 days of paid leave per child,
              monthly child support, the right to work part-time, and a father’s right to take ten
              days of leave in connection to childbirth.
            </p>
          </div>

          <div class="col-md-4">
            <h4>Healthcare</h4>
            <img src="img/relocate/healthcare.jpg" alt="healthcare" />
            <p>
              Sweden offers a high-quality and affordable public healthcare system that is among the
              world’s best. It’s mainly government-funded and decentralized, and although private
              healthcare also exists, the state pays for up to 97% of medical costs. Social
              insurance also helps with sickness, disability, childbirth, and retirement. Dental and
              healthcare are free up until 18 years of age.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="background-mid-section d-flex align-items-center"
      style="
        background: url('img/relocate/join-big-bg.jpg');
        background-size: cover;
        margin-bottom: 0;
        color: #000;
      "
    >
      <div class="viewport">
        <h3 style="font-size: 48px; font-weight: 700">Join our team</h3>
        <p style="width: 70%; margin: 25px auto">
          If you like the sound of working within a Swedish-culture workplace, visit our careers
          page to start your journey.
        </p>
        <router-link to="/careers" class="btn btn-primary">Read more</router-link>
      </div>
      <div class="overlay"></div>
    </div>

    <div class="viewport">
      <div class="more-facts">
        <h3>More facts about Sweden</h3>
        <div class="d-flex justify-content-between">
          <div class="fact">
            <img src="img/icons/icons8-kawaii-coffee-100.png" alt="icon" />
            Swedes love drinking coffee and having 'Fika', at least once a day. Simply put, it’s
            getting together with friends, family, or colleagues with coffee and a sweet treat.
          </div>

          <div class="fact">
            <img src="img/icons/icons8-coconut-cocktail-100.png" alt="icon" />
            While winters may be quite cold and dark, the long hours of daylight make Sweden a truly
            amazing place to be during spring and summer.
          </div>

          <div class="fact">
            <img src="img/icons/icons8-doughnut-100.png" alt="icon" />
            Swedes celebrate regular holidays and other celebratory days, including Shrove Tuesday,
            Waffle Day and Cinnamon Bun Day, to name just a few
          </div>

          <div class="fact">
            <img src="img/icons/icons8-women's-imac-100.png" alt="icon" />
            Swedes aren’t as held-back as they seem. Despite their formal appearance, they are
            always kind, inclusive and helpful.
          </div>
          <div class="fact">
            <img src="img/icons/icons8-recycle-100.png" alt="icon" />
            Tidiness and organization are important in Sweden, such as forming lines in grocery
            stores or waiting in turn at the pharmacy. Recycling is also heavily encouraged.
          </div>
        </div>
      </div>

      <h3>Relocation support</h3>
      <div class="support d-flex align-items-center justify-content-between">
        <div class="support-col">
          <h4>Taking care of every process</h4>
          <p>
            If your most suitable candidate is willing to relocate, we can facilitate and help with
            all aspects of the process on your behalf. As we are based in Eastern Europe, we have a
            strong network of highly qualified candidates who we often assist with relocation. And,
            as we are certified by the Swedish Migration Agency, we can take advantage of a fast
            process for obtaining and renewing working permits (for Sweden) when needed.
          </p>
        </div>
        <div class="support-col">
          <img src="img/relocate/boat-sweden.jpg" alt="relocation support" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Relocating To Sweden | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.relocate {
  h3 {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 60px;
  }
  .content {
    padding: 70px 0;
    .col-md-4 {
      margin-bottom: 30px;

      h4 {
        font-size: 28px;
        font-weight: 700;
        margin: 0;
        text-align: center;
        height: 73px;
      }
      img {
        width: 100%;
        height: auto;
        margin-bottom: 12px;
        border-radius: 4px;
      }
    }
  }
  .more-facts {
    padding: 70px 0;
    .fact {
      width: 19%;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
        display: block;
        margin: 12px auto;
      }
    }
  }
  .support-col {
    width: 48%;
    margin-bottom: 40px;
    h4 {
      font-size: 30px;
      font-weight: 600;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .relocate {
    h3 {
      font-size: 2rem;
    }
    .background-mid-section {
      h3 {
        font-size: 2rem !important;
        margin-bottom: 12px;
      }
    }
    .more-facts {
      display: block !important;
      .d-flex {
        display: block !important;
        .fact {
          width: 100%;
        }
      }
    }
    .support {
      display: block !important;
      .support-col {
        width: 100%;
      }
    }
  }
}
</style>
